import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const Dataview = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // State to hold error messages

  // Define an async function to fetch data when button is clicked
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('/datafetch'); // Ensure this URL is correct
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <button onClick={fetchData} className="btn btn-success btn-lg btn-block">
        Fetch Data
      </button>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-danger">{error}</p>
      ) : (
        data.length > 0 && (
          <table className="table table-bordered table-striped mt-3">
            <thead>
              <tr>
                <th>Emp_Name</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={row.name || index}>
                  <td>{row.emp_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      )}
    </div>
  );
};

export default Dataview;
